var promiseCloseWindow, promiseReject, promiseReject2,promiseInitialResponse,promiseInitialAttempt,promiseReject3;
function initialAttemptValidation(data){
    if(promiseInitialAttempt)promiseInitialAttempt(data)
}

function successfulValidation(data){
    if(promiseCloseWindow)promiseCloseWindow(data)
}
function initialResponseValidation(data){
    if(promiseInitialResponse)promiseInitialResponse(data)
}
function cancelValidation(data){
    if(promiseReject)promiseReject()
    if(promiseReject2)promiseReject2()
}
function loadedValidation(data){
}

export  function promptForMemberJWT(vendorId){


    return [

        // This will be used for closing the prompt
        new Promise(async (resolve,reject)=>{

            promiseCloseWindow=resolve;
            promiseReject2=reject;
        }),

        // This will be used for the initial response
        new Promise(async (resolve,reject)=>{

        // Save these globally
        promiseReject=reject;
        promiseInitialResponse=resolve;

        // Make sure this function actually exists
        if(typeof showMemberValidationFormForVendor !== "undefined"){

            showMemberValidationFormForVendor(vendorId,successfulValidation,cancelValidation,loadedValidation,initialResponseValidation,initialAttemptValidation)
            
        }else{

            alert("An internal error has occured! Unable to verify member data")

            // Reject if the function does not exist
            reject("member validation form function doesn't exist")
        }
    }),

    // This will be used for initial attempts
    new Promise(async (resolve,reject)=>{

        promiseInitialAttempt=resolve;
        promiseReject3=reject;
    })]
}